import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

class TrafficLegend extends Component {
  render () {
    const lowTrafficBlue = '#8FAADC'
    const highTrafficBlue = '#4472C4'
    const trafficThresholds = this.props.trafficThresholds
    return <LegendBox>
      <Header>Verkehrsmengen</Header>
      <LineWrapper>
        <Line height='2px' color={lowTrafficBlue} />
        <Description>&lt; {trafficThresholds[1]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='4px' color={highTrafficBlue} />
        <Description>{trafficThresholds[1]} - {trafficThresholds[2]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='6px' color={highTrafficBlue} />
        <Description>{trafficThresholds[2]} - {trafficThresholds[3]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='8px' color={highTrafficBlue} />
        <Description>{trafficThresholds[3]} - {trafficThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='10px' color={highTrafficBlue} />
        <Description>{trafficThresholds[4]} - {trafficThresholds[5]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='12px' color={highTrafficBlue} />
        <Description>{trafficThresholds[5]} - {trafficThresholds[6]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='14px' color={highTrafficBlue} />
        <Description>{trafficThresholds[6]} - {trafficThresholds[7]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='16px' color={highTrafficBlue} />
        <Description>{trafficThresholds[7]} - {trafficThresholds[8]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='18px' color={highTrafficBlue} />
        <Description>&gt; {trafficThresholds[9]}</Description>
      </LineWrapper>
    </LegendBox>
  }
}

TrafficLegend.propTypes = {
  trafficThresholds: PropTypes.array.isRequired
}

const LegendBox = styled('div')({
  position: 'absolute',
  width: '150px',
  height: '260px',
  backgroundColor: 'white',
  left: '360px',
  bottom: '80px',
  padding: '10px',
  borderRadius: '3px',
  boxShadow: '0 0 0 rgba(0, 0, 0, 0.1)'
})

const Header = styled('div')({
  fontSize: '15px',
  padding: '10px'
})

const Line = styled('div')(({ height, color }) => ({
  height,
  width: '30px',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: '20px'
}))

const LineWrapper = styled('div')({
  marginLeft: '10px',
  marginTop: '0px'
})

const Description = styled('div')({
  display: 'inline-block',
  fontSize: '12px'
})

export default TrafficLegend
