import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, ListItemText } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

/**
 * This dropdown allows the user to select one option.
 */
const Dropdown = ({
  layerObjects,
  onChangeHandler,
  width = '100%',
  height = '40px',
  triggerText,
  selectedLayerObject
}) => {
  // Create a ref for the button to adjust the menu item width to the button with
  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [buttonWidth, setButtonWidth] = useState(0)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    // Set the with of the button here, to ensure the options are loaded
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChange = (layerObject) => {
    onChangeHandler(layerObject)
    handleClose()
  }

  const buttonText = () => {
    if (selectedLayerObject === null) {
      return typeof triggerText === 'undefined' ? 'Netz wählen' : triggerText
    } else {
      return selectedLayerObject.name
    }
  }

  return (
    <div>
      <Button // TODO: className='dropdownButton' used to disable control while in edit mode
        ref={buttonRef}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        style={{
          backgroundColor: '#FFFFFF',
          color: '#222A35',
          width,
          height,
          textTransform: 'uppercase'
        }}
        endIcon={<ArrowDropDownIcon />}
      >
        {buttonText()}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: buttonWidth
          }
        }}
      >
        {layerObjects.map((layerObject) => (
          <MenuItem key={layerObject.id} onClick={() => onChange(layerObject)}>
            <ListItemText primary={layerObject.name} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

Dropdown.propTypes = {
  layerObjects: PropTypes.array, // expected: [{id, name, layerId}]
  onChangeHandler: PropTypes.func, // handler to be called on changes
  width: PropTypes.string,
  height: PropTypes.string,
  triggerText: PropTypes.string,
  selectedLayerObject: PropTypes.object // nullable
}

export default Dropdown
