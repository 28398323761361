import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { addTrafficMap, setVisibleLayerId } from '../../../actions/defaultActions'
import { getLocalStorage, getWebsocketApiUrl } from '../../login/utils'
import { getSimulation, postSimulate } from '../../DataApi.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { setTrafficColor } from '../../colorRanges.js'
import { useSnackbarContext } from '../../SnackbarContext'
import ControlButton from '../../ControlButton'
import Dropdown from '../Dropdown'
import { Endpoints } from '../../constants/Endpoints'
import { LocalStorage } from '../../constants/LocalStorage.js'
import { Status } from '../../constants/Status'
import { MapLayers } from '../../constants/MapLayers.js'
import { getLayerId, getTrafficId } from '../../IdHelper.js'

const AddTrafficMap = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // Redux state
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)
  const roadNetworks = useSelector((state) => state.roadNetworks)

  // Local state
  const [selectedDropdownLayerObject, setSelectedDropdownLayerObject] = useState(null)

  const handleSubmit = async () => {
    const layerObject = selectedDropdownLayerObject
    const roadNetworkId = layerObject.roadNetworkId
    const scenarioId = layerObject.id
    const scenarioName = layerObject.name
    if (trafficMaps.find(element => element.roadNetworkId === roadNetworkId) !== undefined) {
      alert('Für dieses Netz existiert bereits eine Verkehrsmengensimulation.')
      setSelectedDropdownLayerObject(null)
      return
    }

    await simulate(roadNetworkId, scenarioId, scenarioName)

    setSelectedDropdownLayerObject(null)
  }

  const simulate = async (roadNetworkId, scenarioId, scenarioName) => {
    try {
      await postSimulate(dispatch, defaultErrorHandling, logout, roadNetworkId).data
      subscribeToProgress(roadNetworkId, scenarioId, scenarioName)
    } catch (error) {
      defaultErrorHandling(error, logout)
    }
  }

  const subscribeToProgress = async (roadNetworkId, scenarioId, scenarioName) => {
    enqueueSnackbar('Starte Simulation ...')

    // Subscribe to simulation progress websocket - TOOD: see cyface app> useSocketManager
    // await asyncRefreshTokenIfRequired() - should new be automatic
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const endpoint = getWebsocketApiUrl() + Endpoints.SimlationProgress(roadNetworkId)
    const socket = new WebSocket(endpoint, subProtocols)

    socket.onmessage = async (event) => {
      const isNumber = Number.isInteger(Number(event.data))
      if (isNumber) {
        const progress = event.data
        enqueueSnackbar('Simulationsfortschritt: ' + progress + ' %')
      } else {
        console.log('Unrecognized simulation progress message: ' + event.data)
        enqueueSnackbar('Simulation fehlgeschlagen')
      }
      if (event.data === '100') {
        try {
          // Load traffic
          const simulate = (await getSimulation(
            dispatch,
            defaultErrorHandling,
            logout,
            roadNetworkId
          )).data
          const simulationResult = simulate.data.simulationResult
          if (simulationResult.status === Status.Running) {
            throw Error('Simulation still runnning, no results yet!')
          }
          const traffic = setTrafficColor(simulationResult.data)

          // Update map
          const trafficId = getTrafficId(scenarioId)
          const layerId = getLayerId(trafficId)
          addFeature(map, layerId, traffic, MapLayers.TrafficMap)

          // Update redux store
          const trafficMap = {
            id: trafficId,
            name: scenarioName,
            layerId,
            sourceId: layerId,
            roadNetworkId
          }
          dispatch(addTrafficMap(trafficMap))

          dispatch(setVisibleLayerId(trafficMap.layerId, map, visibleLayerId, roadNetworkStyles))
          closeSnackbar()
        } catch (error) {
          if (error.response && error.response.status === 401) {
            enqueueSnackbar('Fehler: Nicht autorisiert. Bitte melden Sie sich erneut an.')
          } else {
            enqueueSnackbar('Ein Fehler ist aufgetreten: ' + error.message)
          }
        }
      }
    }
  }

  /**
   * Updates internal state when the dropdown selection changes.
   */
  const onChange = (layerObject) => {
    setSelectedDropdownLayerObject(layerObject)
  }

  return (
    <StyleBox>
      <StyleHead>Simulation</StyleHead>
      <Dropdown
        selectedLayerObject={selectedDropdownLayerObject}
        layerObjects={roadNetworks.map(roadNetwork => {
          const layerObject = {}
          layerObject.id = roadNetwork.id
          layerObject.name = roadNetwork.name
          layerObject.layerId = roadNetwork.id
          layerObject.roadNetworkId = roadNetwork.id
          return layerObject
        })}
        onChangeHandler={onChange} />

      {selectedDropdownLayerObject === null
        ? ''
        : <SimulateButtonWrapper>
          <ControlButton
            id='startSimulationButton'
            text="Simulation starten"
            onClick={handleSubmit}
            width='100%'
            icon="play_arrow" />
        </SimulateButtonWrapper>}

    </StyleBox>
  )
}

const StyleBox = styled('div')({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  fontSize: '14pt',
  whiteSpace: 'nowrap'
})

const StyleHead = styled('div')({
  fontSize: '18pt',
  color: '#222A35',
  paddingBottom: '10px'
})

const SimulateButtonWrapper = styled('div')({
  paddingTop: '10px'
})

AddTrafficMap.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default AddTrafficMap
