import { MapLayers } from '../constants/MapLayers'
import { getDiffId, getLayerId } from '../IdHelper'

/**
 * Zoom 10 selected so that segments are still rendered when the viewport is set to the bounding box
 * where a large city like Berlin can be seen as a whole.
 */
export const OVERVIEW_ZOOM_LEVEL = 10

/**
 * Commonly used functions for MapBox API.
 */

export const addBaseNet = (map, features, addFeature) => {
  addFeature(
    map,
    getLayerId(features.scenarioId),
    features.roadNetwork,
    MapLayers.RoadNetwork
  )
}

export const addScenarios = (map, features, addFeature) => {
  for (const scenario of features) {
    const scenarioId = scenario._id
    const layerId = getLayerId(scenarioId)
    addFeature(map, layerId, scenario.roadNetwork, MapLayers.RoadNetwork)
  }
}

export const addSimulations = async (map, features, addFeature) => {
  for (const simulation of features) {
    const layerId = getLayerId(simulation.id)
    const simulationData = await simulation.data
    addFeature(map, layerId, simulationData, MapLayers.TrafficMap)
  }
}

export const addDifferences = async (map, features, addFeature) => {
  for (const differences of features) {
    const diffId = getDiffId(differences.roadNetworkId1, differences.roadNetworkId2)
    const layerId = getLayerId(diffId)
    const differenceData = await differences.data
    addFeature(map, layerId, differenceData, MapLayers.DifferenceMap)
  }
}
