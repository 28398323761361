import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Dropdown from '../Dropdown'
import ControlButton from '../../ControlButton'
import { setDifferenceColor } from '../../colorRanges.js'
import { useSnackbarContext } from '../../SnackbarContext'
import { getLocalStorage, getWebsocketApiUrl } from '../../login/utils'
import { Endpoints } from '../../constants/Endpoints'
import { addDifferenceMap, setVisibleLayerId } from '../../../actions/defaultActions'
import { postDifference, getDifference } from '../../DataApi.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { LocalStorage } from '../../constants/LocalStorage.js'
import { Status } from '../../constants/Status'
import { MapLayers } from '../../constants/MapLayers.js'
import { getDiffId, getLayerId } from '../../IdHelper.js'

const AddDifferenceMap = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // Redux state
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const differenceMaps = useSelector((state) => state.differenceMaps)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  // Local state
  const [selectedDropdown1LayerObject, setSelectedDropdown1LayerObject] = useState(null)
  const [selectedDropdown2LayerObject, setSelectedDropdown2LayerObject] = useState(null)

  const handleSubmit = async () => {
    // ATTENTION: The customer wants to select the subtrahend first then the minuend
    // Our API expects the mathematical order, i.e.: minuend - subtrahend = diff
    // Thus, in hope to keep the confusion low we just swap both object here.
    const minuend = selectedDropdown2LayerObject
    const subtrahend = selectedDropdown1LayerObject

    const diffId = getDiffId(minuend.roadNetworkId, subtrahend.roadNetworkId)
    // The customer expects this order
    const differenceName = subtrahend.name + '; ' + minuend.name
    const roadNetworkId1 = minuend.roadNetworkId
    const roadNetworkId2 = subtrahend.roadNetworkId
    if (differenceMaps.find(element => element.roadNetworkId1 === roadNetworkId1 &&
      element.roadNetworkId2 === roadNetworkId2) !== undefined) {
      alert('Für diese Netz-Kombination existiert bereits eine Differenzkarte.')
      setSelectedDropdown1LayerObject(null)
      setSelectedDropdown2LayerObject(null)
      return
    }

    await difference(roadNetworkId1, roadNetworkId2, diffId, differenceName)

    setSelectedDropdown1LayerObject(null)
    setSelectedDropdown2LayerObject(null)
  }

  const difference = async (roadNetworkId1, roadNetworkId2, diffId, differenceName) => {
    try {
      await postDifference(dispatch, defaultErrorHandling, logout, roadNetworkId1, roadNetworkId2)
      subscribeToProgress(roadNetworkId1, roadNetworkId2, diffId, differenceName)
    } catch (error) {
      defaultErrorHandling(error, logout)
    }
  }

  const subscribeToProgress = (roadNetworkId1, roadNetworkId2, diffId,
    differenceName) => {
    enqueueSnackbar('Starte Differenzberechnung ...')

    // Subscribe to difference progress websocket - TOOD: see cyface app> useSocketManager
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const endpoint =
      getWebsocketApiUrl() + Endpoints.DifferenceProgress(roadNetworkId1, roadNetworkId2)
    const socket = new WebSocket(endpoint, subProtocols)

    socket.onmessage = async (event) => {
      const isNumber = Number.isInteger(Number(event.data))
      if (isNumber) {
        const progress = event.data
        enqueueSnackbar('Berechnungsfortschritt: ' + progress + ' %')
      } else {
        console.log('Unrecognized difference progress message: ' + event.data)
        enqueueSnackbar('Berechnung fehlgeschlagen')
      }
      if (event.data === '100') {
        try {
          // Load differnce
          const difference = (await getDifference(
            dispatch,
            defaultErrorHandling,
            logout,
            roadNetworkId1,
            roadNetworkId2
          )).data
          if (difference.data.status === Status.Running) {
            throw Error('Difference calculation still runnning, no results yet!')
          }
          const differenceData = setDifferenceColor(difference.data.data)

          // Update map
          const layerId = getLayerId(diffId)
          addFeature(map, layerId, differenceData, MapLayers.DifferenceMap)

          // Update redux store
          const differenceMap = {
            id: diffId,
            name: differenceName,
            layerId,
            sourceId: layerId,
            roadNetworkId1,
            roadNetworkId2
          }
          dispatch(addDifferenceMap(differenceMap))

          dispatch(setVisibleLayerId(differenceMap.layerId, map, visibleLayerId, roadNetworkStyles))
          closeSnackbar()
        } catch (error) {
          if (error.response && error.response.status === 401) {
            enqueueSnackbar('Fehler: Nicht autorisiert. Bitte melden Sie sich erneut an.')
          } else {
            enqueueSnackbar('Ein Fehler ist aufgetreten: ' + error.message)
          }
        }
      }
    }
  }

  const submitIsDisabled = () => {
    const selection1 = selectedDropdown1LayerObject
    const selection2 = selectedDropdown2LayerObject
    if (selection1 === null || selection2 === null) {
      return true
    } else if (selection1.roadNetworkId === null || selection2.roadNetworkId === null) {
      return true
    } else if (selection1.id === selection2.id) {
      return true
    } else {
      return false
    }
  }

  const onDropdown1Change = (layerObject) => {
    setSelectedDropdown1LayerObject(layerObject)
  }

  const onDropdown2Change = (layerObject) => {
    setSelectedDropdown2LayerObject(layerObject)
  }

  return (
    <StyleBox $trafficMaps={trafficMaps}> {/* transient prop */}
      <StyleHead>Differenzkarte berechnen</StyleHead>

      <Dropdown
        triggerText='Netz 1 wählen'
        selectedLayerObject={selectedDropdown1LayerObject}
        layerObjects={trafficMaps.map(trafficMap => {
          const layerObject = {}
          layerObject.id = trafficMap.id
          layerObject.name = trafficMap.name
          layerObject.layerId = trafficMap.layerId
          layerObject.roadNetworkId = trafficMap.roadNetworkId
          return layerObject
        })}
        onChangeHandler={onDropdown1Change} />

      <Wrapper>
        <Dropdown
          triggerText='Netz 2 wählen'
          selectedLayerObject={selectedDropdown2LayerObject}
          layerObjects={trafficMaps.map(trafficMap => {
            const layerObject = {}
            layerObject.id = trafficMap.id
            layerObject.name = trafficMap.name
            layerObject.layerId = trafficMap.layerId
            layerObject.roadNetworkId = trafficMap.roadNetworkId
            return layerObject
          })}
          onChangeHandler={onDropdown2Change} />
      </Wrapper>

      <Wrapper>
        <ControlButton
          id='submitAddDifference'
          text='Differenz berechnen'
          icon='compare'
          disabled={submitIsDisabled()}
          onClick={handleSubmit}
          sx={{ marginTop: '9px' }} />
      </Wrapper>
    </StyleBox>
  )
}

AddDifferenceMap.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

const StyleBox = styled('div')(({ $trafficMaps }) => ({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  fontSize: '14pt',
  whiteSpace: 'nowrap',
  display: $trafficMaps.length > 1 ? 'block' : 'none'
}))

const StyleHead = styled('div')({
  fontSize: '18pt',
  color: '#222A35',
  paddingBottom: '10px'
})

const Wrapper = styled('div')({
  paddingTop: '10px'
})

export default AddDifferenceMap
