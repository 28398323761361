import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import LoginForm from './components/login/LoginForm'
import RoutingError from './components/RoutingError'
import { Callback } from './components/login/Callback'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from './components/SnackbarContext.js'
import useConfig from './hooks/useConfig'
import theme from './theme'

/**
 * App - The root component of the application.
 *
 * This component serves as the main entry point for the app, wrapping layout, theme, notification
 * context, and routing.
 * It uses Material-UI's `ThemeProvider` for consistent theming across the app, and it manages the
 * routes with `react-router-dom`. It also provides a `SnackbarProvider` for handling notifications
 * and uses a custom `useConfig` hook to fetch configuration at runtime.
 *
 * Key Responsibilities:
 * - **Theming**: The app is wrapped in Material-UI's `ThemeProvider` to apply a consistent theme
 *   across all components. The theme is customized in the `theme.js` file and applied here.
 * - **Routing**: Defines the app's routing structure using React Router. Each route points to
 *   different components like `LoginForm`, `Dashboard`, `RoutingError`, etc.
 * - **Configuration**: Uses the `useConfig` hook to dynamically load runtime configuration. The
 *   config is required for some routes, such as passing `defaultLocation` to the `Dashboard`.
 * - **Notifications**: Provides a `SnackbarProvider` to manage global snackbars (notifications)
 *   throughout the app.
 * - **CSS Reset**: Applies Material-UI's `CssBaseline` to ensure consistent default styling across
 *   browsers.
 *
 * Features:
 * - **Dynamic Configuration**: The configuration values (like `REACT_APP_DefaultLocation`) are
 *   fetched at runtime via the `useConfig` hook. If the configuration is not yet available, the
 *   app renders a loading state.
 * - **Dynamic Routing**: Routes are defined using React Router, which controls navigation between
 *   login, callback, map, and error pages.
 * - **Material-UI Theme**: A custom Material-UI theme is provided via `ThemeProvider`, using styles
 *   and colors from the `theme.js` file.
 *
 * Routes:
 * - `/`: Renders the `LoginForm` component at the root path.
 * - `/login` or `/login/:message`: Renders the `LoginForm` component, with an optional message
 *   parameter.
 * - `/callback`: Renders the `Callback` component, typically used for handling authentication
 *   redirects.
 * - `/map`: Renders the `Dashboard` component, with the map and `defaultLocation` passed from
 *   the runtime config.
 * - `:errorRoute`: Catches invalid or unknown routes, rendering the `RoutingError` component.
 *
 * Lifecycle:
 * - **Config Loading**: The app checks for configuration using the `useConfig` hook. If the config
 *   isn't loaded yet, a "Loading config..." message is shown.
 * - **Error Handling**: If config fetching fails, an error message can be displayed.
 *
 * Configuration (via `useConfig`):
 * - `REACT_APP_DefaultLocation`: The default location to be passed to the `Dashboard`.
 *
 * Example Usage:
 * The app uses `BrowserRouter` for routing, and it renders different components based on the URL
 * path. The `ThemeProvider` applies a global theme for consistent UI styling, and the
 * `SnackbarProvider` allows for triggering snackbars across the app.
 *
 * Example:
 * import App from './App';
 *
 * function Main() {
 *   return <App />;
 * }
 *
 * @returns {JSX.Element} The main app component wrapped in providers (theme, snackbar, and router),
 * or a loading message while the config is being fetched.
 */
const App = () => {
  // Hooks
  const config = useConfig()

  // Extract necessary configuration
  const defaultLocation = config?.REACT_APP_DefaultLocation || null

  // Return loading state if config is not yet available
  if (!config) {
    return <div>Loading config...</div>
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* fix MuiCssBaseline border-box above to be applied */}
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LoginForm />} />
            <Route path='/login' element={<LoginForm />} />
            <Route path="/login/:message" element={<LoginForm />} />
            <Route path="/callback" element={<Callback />} />
            <Route path='/map' element={<Dashboard
              accessToken={ process.env.REACT_APP_MAPBOX_TOKEN }
              defaultLocation={defaultLocation.slice(1, -1).split(', ').map(Number)}
              />} />
            <Route path=':errorRoute' element={<RoutingError />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
