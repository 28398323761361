import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import RoadProperties from '../../sidebar/roadNetworks/RoadProperties'
import EditRelation from '../../sidebar/roadNetworks/EditRelation'

const RoadLegend = ({ map, logout }) => {
  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  const activeStyle = roadNetworkStyles.find(style => style.active)
  const property = RoadProperties[activeStyle.key]
  const unifiedView = activeStyle.key === RoadProperties.unified.key && !editMode.active
  const relationsView = activeStyle.key === RoadProperties.relations.key && !editMode.active
  const relationsEdit = activeStyle.key === RoadProperties.relations.key && editMode.active

  return unifiedView || relationsView
    ? null
    : relationsEdit
      ? (
        // Relations edit mode
        <EditRelation map={map} logout={logout} />
        )
      : ( // Default
        <LegendBox>
          <Header>{property.label}</Header>
          {
            property.options.map(option => {
              return <LineWrapper key={option.value}>
                <Stroke height='6px' color={option.color} />
                <Description>{option.label}</Description>
              </LineWrapper>
            })
          }
        </LegendBox>
        )
}

RoadLegend.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

const LegendBox = styled('div')({
  position: 'absolute',
  width: '340px',
  height: '217px',
  backgroundColor: 'white',
  left: '360px',
  bottom: '300px',
  padding: '10px',
  borderRadius: '3px',
  boxShadow: '0 0 0 rgba(0, 0, 0, 0.1)'
})

export const Header = styled('div')({
  fontSize: '15px',
  padding: '10px'
})

const Stroke = styled('div')(({ height, color }) => ({
  height,
  width: '30px',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: '20px'
}))

export const LineWrapper = styled('div')({
  marginLeft: '10px',
  marginTop: '0px'
})

export const Description = styled('div')({
  display: 'inline-block',
  fontSize: '12px'
})

export default RoadLegend
