import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import TrafficMapsView from './trafficMaps/TrafficMapsView'
import DifferenceMapsView from './differenceMaps/DifferenceMapsView'
import RoadNetworksView from './roadNetworks/RoadNetworksView'

class Sidebar extends Component {
  render () {
    return (
      <StyleSidebar>
        <RoadNetworksView
          map={this.props.map}
          logout={this.props.logout}
          addFeature={this.props.addFeature} />

        <TrafficMapsView
          map={this.props.map}
          logout={this.props.logout}
          addFeature={this.props.addFeature} />

        <DifferenceMapsView
          map={this.props.map}
          logout={this.props.logout}
          addFeature={this.props.addFeature} />
      </StyleSidebar>
    )
  }
}

const StyleSidebar = styled('div')({
  position: 'fixed',
  height: 'calc(100% - 69px)',
  width: '350px',
  zIndex: 997,
  backgroundColor: '#FFFFFF',
  fontFamily: "'Helvetica', 'Arial', sans-serif",
  color: '#222A35',
  borderRight: '1px solid #222A35',
  overflowY: 'auto'
})

Sidebar.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default Sidebar
