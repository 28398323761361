import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import RadioGroup from './RadioGroup'
import ControlButton from '../../ControlButton'
import RoadProperties from './RoadProperties'

const EditFeatureDialog = ({
  properties,
  handleFeatureChange,
  persistChanges,
  resetWayId
}) => {
  return (
    <StylePopUp>
      <Row>
        <Column>
          <Label>Merkmal</Label>
        </Column>
        <Column>
          <Label>Ausprägung</Label>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Zulässige Höchstgeschwindigkeit des MIV</Label>
        </Column>
        <Column data-testid = 'maxSpeedColumn'>
          <RadioGroup
            name='maxSpeed'
            options={RoadProperties.maxSpeed.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.maxSpeed}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Führungsform</Label>
        </Column>
        <Column data-testid = 'roadStyleSimplifiedColumn'>
          <RadioGroup
            name='roadStyleSimplified'
            options={RoadProperties.roadStyleSimplified.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.roadStyleSimplified}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Oberflächenbeschaffenheit</Label>
        </Column>
        <Column data-testid = 'surfaceColumn'>
          <RadioGroup
            name='surface'
            options={RoadProperties.surface.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.surface}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Qualität des Fahrbahnbelages</Label>
        </Column>
        <Column data-testid = 'surfaceQualityColumn'>
          <RadioGroup
            name='surfaceQuality'
            options={RoadProperties.surfaceQuality.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.surfaceQuality}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>

      <ButtonContainer>
        <ControlButton
          onClick={() => {
            persistChanges()
            resetWayId()
          }}
          text='Speichern'
          width='150px' />
        <ControlButton
          onClick={() => {
            resetWayId()
          }}
          text='Abbrechen'
          width='150px' />
      </ButtonContainer>
    </StylePopUp>
  )
}

EditFeatureDialog.propTypes = {
  properties: PropTypes.object.isRequired,
  handleFeatureChange: PropTypes.func.isRequired,
  persistChanges: PropTypes.func.isRequired,
  resetWayId: PropTypes.func.isRequired
}

const HALF_SIDEBAR_SIZE = 350 / 2
const POPUP_SIZE = 1100
const HALF_POPUP_SIZE = POPUP_SIZE / 2
const SMALL_POPUP_SIZE = 850
const HALF_SMALL_POPUP_SIZE = SMALL_POPUP_SIZE / 2

const StylePopUp = styled('div')(({ theme }) => ({
  position: 'fixed',

  height: '80vh',
  bottom: '8vh',

  width: '90vw',
  maxWidth: `${POPUP_SIZE}px`,
  right: `calc(50% - ${HALF_SIDEBAR_SIZE}px - ${HALF_POPUP_SIZE}px)`,

  fontFamily: "'Helvetica', 'Arial', sans-serif",
  padding: '20px',
  backgroundColor: '#FFFFFF',

  border: 'solid 2px #222A35',
  borderRadius: '4px',
  zIndex: 1, // or auto

  // The above is for > 1200 px width

  [theme.breakpoints.down('xl')]: { // 1536 px width
    width: '90vw',
    maxWidth: `${SMALL_POPUP_SIZE}px`,
    right: `calc(50% - ${HALF_SIDEBAR_SIZE}px - ${HALF_SMALL_POPUP_SIZE}px)`
  },

  [theme.breakpoints.down('lg')]: { // < 1200 px width
    width: '80vw',
    right: '10vw'
  },
  [theme.breakpoints.down('md')]: { // < 900 px width
    height: '90vh',
    bottom: '5vh',
    width: '80vw',
    right: '10vw'
  },
  [theme.breakpoints.down('sm')]: { // < 600 px width
    height: '98vh',
    bottom: '1vh',
    width: '98vw',
    right: '1vw'
  }
}))

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '10px'
})

const Column = styled('div')({
  padding: '10px',
  '&:first-of-type': {
    flex: 1
  },
  '&:nth-of-type(2)': {
    flex: 3
  }
})

const Label = styled('p')({
  fontWeight: 'bold',
  width: '200px'
})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px'
})

export default EditFeatureDialog
