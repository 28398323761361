import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Button, Tooltip } from '@mui/material'
import { Login, PowerSettingsNew, CallSplit, Compare, PlayArrow, Delete } from '@mui/icons-material'

const iconMap = {
  login: Login,
  power_settings_new: PowerSettingsNew,
  call_split: CallSplit,
  compare: Compare,
  play_arrow: PlayArrow,
  delete: Delete
}

/**
 * This button wrapper allows to turn different settings on or off.
 */
const ControlButton = ({
  text,
  icon,
  onClick,
  active,
  disabled,
  type,
  width = 'inherit',
  tooltip,
  margin = '0px 5px 5px 0px',
  visible = true,
  height = '40px',
  testid,
  backgroundColor = '#FFFFFF',
  sx
}) => {
  const IconComponent = iconMap[icon]

  const commonProps = {
    onClick,
    disabled,
    type,
    'data-testid': testid,
    sx: {
      backgroundColor: active === undefined
        ? backgroundColor
        : active ? backgroundColor : '#999999',
      color: '#222A35', // dark blue - primary Radsim color
      width,
      height,
      margin,
      '&:hover': {
        backgroundColor: active === undefined
          ? backgroundColor
          : active ? backgroundColor : '#888888'
      },
      ...sx
    }
  }

  if (!visible) return null

  const buttonContent = text
    ? (
    <Button {...commonProps} variant="outlined" startIcon={
      IconComponent ? <IconComponent /> : null
    }>
      {text}
    </Button>
      )
    : (
    <IconButton {...commonProps}>
      {IconComponent ? <IconComponent /> : null}
    </IconButton>
      )

  return tooltip
    ? (
        <Tooltip title={tooltip} enterDelay={1000}>
          {buttonContent}
        </Tooltip>
      )
    : (
        buttonContent
      )
}

ControlButton.propTypes = {
  visible: PropTypes.bool,
  // undefined if no text just an icon is wanted
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string, // undefined if no icon is wanted
  onClick: PropTypes.func, // undefined for dummy buttons
  disabled: PropTypes.bool,
  type: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  active: PropTypes.bool, // undefined for buttons which cannot be "active"
  tooltip: PropTypes.string,
  backgroundColor: PropTypes.string,
  testid: PropTypes.string,
  sx: PropTypes.object
}

export default ControlButton
