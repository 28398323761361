import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { setVisibleLayerId, deleteRoadNetwork, deleteTrafficMap, deleteDifferenceMap } from '../../../actions/defaultActions'
import { deleteDifference, deleteScenario, deleteSimulation } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { styled } from '@mui/material/styles'
import CustomCheckbox from '../../CustomCheckbox'
import ControlButton from '../../ControlButton'
import useMapFeatureHandlers from '../../map/useMapFeatureHandlers'

const RoadNetworkEntry = ({ map, logout, roadNetwork }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const differenceMaps = useSelector((state) => state.differenceMaps)

  const {
    enterEditMode,
    exitEditMode
  } = useMapFeatureHandlers(map, differenceMaps, trafficMaps, logout)

  const onCheckboxChanged = (e, roadNetwork) => {
    const checkbox = e.target
    const makeVisible = checkbox.checked
    const newVisibleLayerId = makeVisible ? roadNetwork.layerId : null
    dispatch(setVisibleLayerId(newVisibleLayerId, map, visibleLayerId, roadNetworkStyles))
  }

  const deleteNetwork = async (roadNetwork) => {
    // Remove DifferenceMaps bound to that RoadNetwork
    const deleteDifferencePromises = differenceMaps.map((differenceMap) => {
      if (differenceMap.roadNetworkId1 === roadNetwork.id ||
              differenceMap.roadNetworkId2 === roadNetwork.id) {
        return deleteDifference(
          dispatch,
          defaultErrorHandling,
          logout,
          differenceMap.roadNetworkId1,
          differenceMap.roadNetworkId2
        ).then(() => {
          map.removeLayer(differenceMap.layerId)
          map.removeSource(differenceMap.sourceId)
          dispatch(deleteDifferenceMap(differenceMap.id))
        })
      }
      return null
    }).filter(Boolean) // Filter undefined values (when if condition is not met)

    // Remove TrafficMaps bound to that RoadNetwork
    const deleteTrafficPromises = trafficMaps.map((trafficMap) => {
      if (trafficMap.roadNetworkId === roadNetwork.id) {
        return deleteSimulation(dispatch, defaultErrorHandling, logout, trafficMap.roadNetworkId)
          .then(() => {
            map.removeLayer(trafficMap.layerId)
            map.removeSource(trafficMap.sourceId)
            dispatch(deleteTrafficMap(trafficMap.id))
          })
      }
      return null
    }).filter(Boolean) // Filter undefined values (when if condition is not met)

    // Wait for all differenceMaps and trafficMaps to be deleted
    await Promise.all([...deleteDifferencePromises, ...deleteTrafficPromises])

    // Remove RoadNetwork
    await deleteScenario(dispatch, defaultErrorHandling, logout, roadNetwork.id)
    map.removeLayer(roadNetwork.layerId)
    map.removeSource(roadNetwork.sourceId)
    dispatch(deleteRoadNetwork(roadNetwork.id))
  }

  const showEditModeButtons = roadNetwork.editable && roadNetwork.layerId === visibleLayerId

  return (
    <div key={roadNetwork.id}>
      <CheckboxWrapper>
        <CustomCheckbox
          id={roadNetwork.id}
          label={roadNetwork.name}
          value={roadNetwork.layerId}
          checked={roadNetwork.layerId === visibleLayerId}
          onChange={(e) => onCheckboxChanged(e, roadNetwork)}
          disabled={editMode.active}
          sx={{ padding: '3px 9px 3px 9px' }}
        />
      </CheckboxWrapper>

      {showEditModeButtons
        ? (
            editMode.active
              ? (
          <SaveButtonWrapper>
            <ControlButton
              id='roadNetworkSaveButton'
              width='120px'
              height='32px'
              onClick={(e) => exitEditMode(e, dispatch)}
              text='Speichern'
            />
          </SaveButtonWrapper>
                )
              : (
          <EditButtonWrapper>
            <ControlButton
              id='roadNetworkEditButton'
              width='120px'
              height='32px'
              onClick={(e) => enterEditMode(e, roadNetwork, dispatch)}
              text='Bearbeiten'
            />
          </EditButtonWrapper>
                )
          )
        : ''}

      {showEditModeButtons
        ? (
        <ControlButton
          onClick={() => deleteNetwork(roadNetwork, dispatch)}
          icon='delete'
          width='50px'
          height='32px'
        />
          )
        : ''}
    </div>
  )
}

RoadNetworkEntry.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  roadNetwork: PropTypes.object.isRequired
}

const CheckboxWrapper = styled('div')({
  display: 'inline',
  paddingRight: '10px'
})

const SaveButtonWrapper = styled('div')({
  display: 'inline',
  paddingRight: '10px'
})

const EditButtonWrapper = styled('div')({
  display: 'inline',
  paddingRight: '10px'
})

export default RoadNetworkEntry
